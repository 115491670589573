import { axiosInstance } from '@/plugins/axios';

export function hasBookitSession(context) {
  const config = {
    method: 'get',
    url: '/bookit/has-bookit-session',
  };

  return axiosInstance
    .request(config)
    .then((response) => {
      console.log('store.bookit.actions hasBookitSession() then', response);
      // Set current booking to an empty array if user has no session
      if (response.data.session === false) {
        context.commit('setCurrentBooking', { booking: [] });
      }

      return response;
    })
    .catch((error) => {
      console.log('store.bookit.actions hasBookitSession() catch', error);
      if (
        error.response.status === 401 &&
        error.response.data.session.toString() === 'false' &&
        error.response.data.currentBookingQty !== 0
      ) {
        // #TODO address this session-expired event
        // window.bookit.$emit('session-expired');
        context.commit('setCurrentBooking', { booking: [] });
      }

      throw new Error('Error checking for BOOKIT session');
    });
}

/**
 * This marks the Booking as Incomplete
 * @param context
 * @param payload
 * @return {Promise<T>}
 */
export function apiConfirmBooking(context, payload) {
  const { data } = payload;
  const config = {
    method: 'post',
    url: '/bookit/confirm-booking',
    data,
  };

  return axiosInstance
    .request(config)
    .then((response) => {
      console.log('store.bookit.actions apiConfirmBooking() then', response);

      return response;
    })
    .catch((error) => {
      console.log('store.bookit.actions apiConfirmBooking() catch', error);
      throw new Error('Error confirming Booking');
    });
}

export function apiExitBooking(context) {
  console.log('store.bookit.actions apiExitBooking() started');
  const config = {
    method: 'get',
    url: '/bookit/exit-booking',
  };

  console.log('store.bookit.actions apiExitBooking() config', config);

  return axiosInstance
    .request(config)
    .then((response) => {
      console.log('store.bookit.actions apiExitBooking() then', response);
      context.commit('setCurrentBooking', { booking: [] });

      return response;
    })
    .catch((error) => {
      console.log('store.bookit.actions apiExitBooking() catch', error);
      throw new Error('Error exiting booking');
    });
}

export function apiGetBooking(context) {
  const config = {
    method: 'get',
    url: '/bookit/get-bookings-extended',
  };

  return axiosInstance
    .request(config)
    .then((response) => {
      console.log('store.bookit.actions apiGetBooking() then', response);
      context.commit('setCurrentBooking', { booking: response.data });

      return response;
    })
    .catch((error) => {
      console.log('store.bookit.actions apiGetBooking() catch', error);
      throw new Error('Error loading Booking');
    });
}

export function apiLoadBooking(context, payload) {
  console.log('store.bookit.actions apiLoadBooking() started', payload);
  const { bookingNumber } = payload;
  const config = {
    method: 'get',
    url: '/phone/booking',
    params: {
      bookingNumber: bookingNumber,
    },
  };

  console.log('store.bookit.actions apiLoadBooking() config', config);

  return axiosInstance
    .request(config)
    .then((response) => {
      console.log('store.bookit.actions apiLoadBooking() then', response.data);
      context.commit('setCurrentBooking', { booking: response.data });

      return response;
    })
    .catch((error) => {
      console.log('store.bookit.actions apiLoadBooking() catch', error);
      throw new Error('Error loading Booking');
    });
}

export function apiProcessPayments(context, payload) {
  console.log('store.bookit.actions apiProcessPayments() started', payload);
  const config = {
    method: 'post',
    url: '/bookit/process-payments',
    data: payload,
  };

  console.log('store.bookit.actions apiProcessPayments() config', config);

  return axiosInstance
    .request(config)
    .then((response) => {
      console.log('store.bookit.actions apiProcessPayments() then', response);

      return response;
    })
    .catch((error) => {
      console.error('store.bookit.actions apiProcessPayments() catch', error);
      error.message = 'Error processing payment at BOOKIT';
      throw error;
    });
}

/**
 * Submit payment/charge request
 *
 * @param context
 * @param payload
 * @return {Promise<T>}
 */
export function apiSubmitPayment(context, payload) {
  console.log('store.bookit.actions apiSubmitPayment() started', payload);
  const config = {
    method: 'post',
    url: '/phone/pay',
    data: payload,
  };

  console.log('store.bookit.actions apiSubmitPayment() config', config);

  return axiosInstance
    .request(config)
    .then((response) => {
      console.log('store.bookit.actions apiSubmitPayment() then', response);

      return response;
    })
    .catch((error) => {
      console.error('store.bookit.actions apiSubmitPayment() catch', error);
      throw error;
    });
}

export function setCurrentBooking(context, payload) {
  context.commit('setCurrentBooking', payload);
}
